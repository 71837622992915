import _jsx from "./jsx.js";
import _typescript from "./typescript.js";
var exports = {};
var refractorJsx = _jsx;
var refractorTypescript = _typescript;
exports = tsx;
tsx.displayName = "tsx";
tsx.aliases = [];

function tsx(Prism) {
  Prism.register(refractorJsx);
  Prism.register(refractorTypescript);

  (function (Prism) {
    var typescript = Prism.util.clone(Prism.languages.typescript);
    Prism.languages.tsx = Prism.languages.extend("jsx", typescript); // This will prevent collisions between TSX tags and TS generic types.
    // Idea by https://github.com/karlhorky
    // Discussion: https://github.com/PrismJS/prism/issues/2594#issuecomment-710666928

    var tag = Prism.languages.tsx.tag;
    tag.pattern = RegExp(/(^|[^\w$]|(?=<\/))/.source + "(?:" + tag.pattern.source + ")", tag.pattern.flags);
    tag.lookbehind = true;
  })(Prism);
}

export default exports;